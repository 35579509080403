<template>
  <SideBar @toggle="$emit('toggle')" class="is-large" ref="sideBar">
    <div>
      <div><PageTitle :title="title"/></div>
      <div class="has-background-white-ter doodle-display-box">
        <Spinner v-show="showInProgressSection && !isMounted" message="JDoodle is loading your projects, please wait..."/>
        <div class="column is-8 is-offset-2" v-show="!isMounted && doodleForm.errorMessage">
          <DoodleMessage :message="doodleForm.errorMessage" message-class="has-text-danger"/>
        </div>
        <div>
          <div>
            <div v-show="showMainSection">
              <div class="columns is-mobile file-div is-multiline">
                <div class="column is-4 file-list is-paddingless">
                  <div class="file-list-items" v-for="project in projects" :key="project.id">
                    <div class="file-list-item" @click="loadFile(project)" v-bind:class="{'is-active': isSelected(project)}">
                      <font-awesome-icon icon="file-alt" class="has-text-danger"/>&nbsp;&nbsp;{{mmnt(project.executedAt)}}
                    </div>
                  </div>
                </div>

                <div class="column is-8 text-center" v-show="showInProgressSection">
                  <Spinner message="JDoodle is loading your file, please wait..."/>
                </div>
                <div class="column is-8 text-center" v-show="doodleForm.errorMessage">
                  <DoodleMessage :message="doodleForm.errorMessage" message-class="has-text-danger"/>
                </div>
                <div class="column is-8 code-editor-div"  v-show="!showInProgressSection && !doodleForm.errorMessage">
                  <div id="myFilesEditor" class="code-editor"></div>
                </div>
                <div class="column is-12 buttons has-text-centered">
                  <div>
                    <button class="button is-info" v-show="project" @click="openProject">Open</button>
                    <button class="button" @click="$refs.sideBar.emitToggle()">Close</button>
                  </div>
                </div>
                <div class="column is-12 has-text-centered ">
                  <label class="checkbox has-text-weight-semibold store-message">
                    <input type="checkbox" v-model="isLocalStoreEnabled" v-on:change="toggleRecentStorage">
                    Remember execution history in this browser for future sessions<br/> (please note: this change is across all the languages, and this option will show this history to all the users using JDoodle in this browser).
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SideBar>
</template>

<script>
import formMixin from '../assets/javascript/form-mixin'
import SideBar from './Utils/SideBar'
import PageTitle from './PageTitle'
import moment from 'moment'
import DoodleMessage from './Utils/DoodleMessage'
import Spinner from './Utils/Spinner'
import { ACE_UTIL } from '../assets/javascript/ace-util'
import { IDE_CONST } from '../assets/javascript/constants'
import { eventBus } from './../assets/javascript/event-bus'
import { HTML_IDE_UTIL } from '../assets/javascript/html-ide-util'

export default {
  name: 'executionHistory',
  mixins: [formMixin],
  props: ['language', 'languageName', 'versions', 'storage', 'aceLanguageCode'],
  components: { Spinner, DoodleMessage, PageTitle, SideBar },
  data: function () {
    return {
      projects: [],
      isMounted: false,
      project: null,
      isLocalStoreEnabled: false
    }
  },
  mounted () {
    let recents = this.storage.getItem('JDoodleRecent')
    if (recents && JSON.parse(recents)[this.language]) {
      this.projects = JSON.parse(recents)[this.language]
    } else {
      this.projects = []
    }

    if (this.storage === localStorage) {
      this.isLocalStoreEnabled = true
    }

    if (this.projects.length < 1) {
      this.doodleForm.errorMessage = 'No recent code exectutions found for ' + this.languageName
    } else {
      this.isMounted = true
      this.initFileEditor()
    }
  },
  methods: {
    openProject () {
      eventBus.$emit((this.language === 'html') ? 'HtmlIdeOpenProject' : 'IdeOpenProject', { project: this.project })
      this.$refs.sideBar.emitToggle()
    },
    loadFile (project) {
      this.project = project

      let script

      if (this.language === 'html') {
        script = HTML_IDE_UTIL.getHtmlCode(this.project.docType, this.project.htmlHead, this.project.htmlBody, this.project.jsCode, this.project.cssCode)
      } else {
        script = project.script +
          '\n\n\nLanguage Version:' + this.versions[(project.versionIndex) ? project.versionIndex : 0] + '\n\n\nResult...\n\n' + project.output
      }

      window.ace.edit(IDE_CONST.FILE_EDITOR).getSession().setValue(script)
    },
    initFileEditor () {
      this.fileEditor = ACE_UTIL.initEditor(IDE_CONST.FILE_EDITOR, this.aceLanguageCode)
      this.fileEditor.renderer.setShowGutter(true)
      this.fileEditor.setReadOnly(true)

      if (this.language === 'whitespace') {
        this.codeEditor.setShowInvisibles(true)
      }
    },
    isSelected (project) {
      if (this.project) {
        return project === this.project
      }

      return false
    },
    mmnt: function (date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a')
    },
    toggleRecentStorage () {
      if (this.isLocalStoreEnabled) {
        localStorage.setItem('JDoodleRecent', this.storage.getItem('JDoodleRecent'))
        sessionStorage.removeItem('JDoodleRecent')
      } else {
        sessionStorage.setItem('JDoodleRecent', this.storage.getItem('JDoodleRecent'))
        localStorage.removeItem('JDoodleRecent')
      }

      eventBus.$emit('IdeStorageChanged')
    }
  },
  computed: {
    title () {
      return 'Recent ' + this.languageName + ' Executions'
    },
    showMainSection () {
      return this.isMounted
    }
  }
}
</script>

<style scoped lang="scss">
  @import "./../assets/style/screen-size.scss";
  @import "./../assets/style/ide.scss";

  .doodle-display-box {
    margin: 0.25em;
    padding: 1.5em;
  }

  .file-div {
  }

  .file-list {
    max-height: 300px;
    background: white;
    overflow: scroll;
  }

  .file-list::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  .file-list::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  .file-list-item {
    font-size: 0.9em;
    padding: 0.5em;
    font-weight: 600;
    border-bottom: 1px solid #f3f0f0;

    @media screen and (max-width: $tablet) {
      font-size: 0.8em;
    }
  }

  .file-list-item:hover {
    cursor: pointer;
    background: lightgrey;
  }

  #myFilesEditor {
    min-height: 300px;
    margin: 0;
  }

  .code-editor-div {
    padding-top: 0;
    padding-right: 0;
  }

  .share-div {
    font-size: 0.9em;
    font-weight: 600;
    padding: 0.6em;
  }

  .share-box {
    display: inline;
    padding: 0.5em;
  }

  .ide-menu-box > .box {
    margin: 1em;
    background: hsl(0, 0%, 96%);
  }

  .confirm-text {
    font-size: 0.9em;
    margin-bottom: 1em;
    color: white;
  }

  .file-list-item.is-active {
    background: lightgray;
  }

  .store-message {
    font-size: 0.85em;
  }
</style>
